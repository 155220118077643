
// UTILS FUNCTIONS

function preventDefault(e) {
    e = e || window.event;
    if (e.preventDefault)
        e.preventDefault();
    e.returnValue = false;
  }
function preventDefaultForScrollKeys(e) {
      if (keys[e.keyCode]) {
          preventDefault(e);
          return false;
      }
  }

function disableScroll() {
    if (window.addEventListener) // older FF
        window.addEventListener('DOMMouseScroll', preventDefault, false);
        document.addEventListener('wheel', preventDefault, {passive: false}); // Disable scrolling in Chrome
        window.addEventListener("keydown", arrow_keys_handler, false);
        document.querySelector('html').classList.add('overflow');
        document.querySelector('body').classList.add('overflow');
  }

  function enableScroll() {
      if (window.removeEventListener)
          window.removeEventListener('DOMMouseScroll', preventDefault, false);
      document.removeEventListener('wheel', preventDefault, {passive: false}); // Enable scrolling in Chrome
      window.onmousewheel = document.onmousewheel = null;
      window.onwheel = null;
      window.ontouchmove = null;
      document.onkeydown = null;
      window.removeEventListener("keydown", arrow_keys_handler, false);
      document.querySelector('html').classList.remove('overflow');
      document.querySelector('body').classList.remove('overflow');
  }

  var arrow_keys_handler = function(e) {
    switch(e.keyCode){
      case 37: case 39: case 38:  case 40: // Arrow keys
      case 32: e.preventDefault(); break; // Space
      default: break; // do not block other keys
    }
  };


function getOffsetTop(element) {
  let offsetTop = 0;
  while(element) {
    offsetTop += element.offsetTop;
    element = element.offsetParent;
  }
  return offsetTop;
}


function getOffsetLeft(element) {
  let offsetLeft = 0;
  while(element) {
    offsetLeft += element.offsetLeft;
    element = element.offsetParent;
  }
  return offsetLeft;
}


function randomIntFromRange(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

function randomColor(colors) {
  return colors[Math.floor(Math.random() * colors.length)]
}

function distance(x1, y1, x2, y2) {
  const xDist = x2 - x1
  const yDist = y2 - y1

  return Math.sqrt(Math.pow(xDist, 2) + Math.pow(yDist, 2))
}

module.exports = { disableScroll, enableScroll, scrollTo, getOffsetTop, getOffsetLeft, randomIntFromRange, randomColor, distance }

// utils.disableScroll();
