import utils from '../utils/utils'
import anime from '../libs/anime'
import globalVar from '../utils/globalVar'

class Home {
  constructor() {
    let cache = {};
    let scrollPos = 0;
    let pathIndex = 0;
    let initFunc = () => {
      initCache();
      registerEvents();
      // loader();

      // Function to be use only if there isn't a loader
      withoutLoader();
    }

    let initCache = () => {
      cache.body = document.querySelector('body');
      cache.header = document.querySelector('header');
      cache.animeTxt = document.querySelectorAll('.anime-txt_container');
      cache.animeFact = document.querySelectorAll('.anime-fact_container');
      cache.animeFactImg = document.querySelectorAll('.anime-fact_img');
      cache.animeComment = document.querySelectorAll('.anime-comment_container');
      cache.animeCommentNum = document.querySelectorAll('.anime-comment_number');
      cache.animeCommentNumMobile = document.querySelectorAll('.sec6 .tablet .number');

      cache.animeCommentNumFemmes = document.querySelectorAll('.anime-comment_femmesnumber');
      cache.animeCommentNumMobileFemmes = document.querySelectorAll('.sec_femmesagriculture .tablet .femmenumber');

      cache.animeProd = document.querySelectorAll('.producteur');
      cache.bg_dark = document.querySelectorAll('.bg_dark');
      cache.bg_green = document.querySelectorAll('.bg_green');
      // cache.loader = document.querySelector('.loader');
      // cache.loaderSvg = document.querySelector('.loader .anime_svg');
      // cache.loaderSvgPath = cache.loaderSvg.querySelectorAll("path, line");
    };

    let registerEvents = () => {

      for (let i = 0; i < cache.animeCommentNum.length; i++) {
        cache.animeCommentNum[i].addEventListener('click', numberClick);
      }
      for (let i = 0; i <  cache.animeCommentNumMobile.length; i++) {
        cache.animeCommentNumMobile[i].addEventListener('click', numberClickMobile);
      }

      for (let i = 0; i < cache.animeCommentNumFemmes.length; i++) {
        cache.animeCommentNumFemmes[i].addEventListener('click', numberClick);
      }
      for (let i = 0; i <  cache.animeCommentNumMobileFemmes.length; i++) {
        cache.animeCommentNumMobileFemmes[i].addEventListener('click', numberClickMobile);
      }

    };

    let withoutLoader = () => {
      utils.disableScroll();
      anime({
        targets: globalVar.scrollElement,
        duration: 0,
        scrollTop: 0,
        delay: 300,
        complete: function() {
          utils.enableScroll();
          animePage();
          window.addEventListener('scroll', animePage);
          anime({
            targets: document.querySelector('header'),
            translateY:['-50%', '0%'],
            opacity: [0,1],
            delay: 200,
            duration: 400,
            easing: 'easeInSine',
            complete: function() {
              if(window.location.hash) {
                document.querySelector('body').classList.add('scroll');
                var target = document.querySelector(window.location.hash);
                anime({
                  targets: globalVar.scrollElement,
                  scrollTop: (globalVar.scrollElement.scrollTop+target.getBoundingClientRect().top),
                  duration: 800,
                  delay: 500,
                  easing: 'easeOutQuad',
                  complete: function () {
                    document.querySelector('body').classList.remove('scroll');
                  }
                });
              }
            }
          });
        }
      });
    }

    // let loader = () => {
    //   utils.disableScroll();
    //   for (var i = 0; i < cache.loaderSvgPath.length; i++) {
    //     anime.set(cache.loaderSvgPath[i], {strokeDashoffset:anime.setDashoffset});
    //     cache.loaderSvgPath[i].style.strokeDashoffset = (parseInt(cache.loaderSvgPath[i].style.strokeDashoffset)*2)+1;
    //   }
    //   anime({
    //     targets: globalVar.scrollElement,
    //     duration: 0,
    //     scrollTop: 0,
    //   });
    //   animePath(pathIndex);
    // }
    //
    // let animePath = (i) => {
    //   pathIndex++;
    //   let pathWidth = (cache.loaderSvgPath[i].getBoundingClientRect().width/cache.loaderSvg.getBoundingClientRect().width);
    //   anime({
    //     targets: cache.loaderSvgPath[i],
    //     strokeDashoffset: [parseInt(cache.loaderSvgPath[i].style.strokeDashoffset), parseInt(cache.loaderSvgPath[i].style.strokeDashoffset)/2],
    //     easing: 'linear',
    //     duration: 3500*pathWidth,
    //     complete: function() {
    //       if(pathIndex < cache.loaderSvgPath.length) {
    //         animePath(pathIndex);
    //       }
    //       else {
    //         anime({
    //           targets: cache.loader,
    //           opacity: [1,0],
    //           easing: 'easeInSine',
    //           duration: 1600,
    //           complete: function() {
    //             cache.loader.remove();
    //             utils.enableScroll();
    //             animePage();
    //             window.addEventListener('scroll', animePage);
    //             anime({
    //               targets: document.querySelector('header'),
    //               translateY:['-50%', '0%'],
    //               opacity: [0,1],
    //               delay: 200,
    //               duration: 400,
    //               easing: 'easeInSine',
    //               complete: function() {
    //                 if(window.location.hash) {
    //                   document.querySelector('body').classList.add('scroll');
    //                   var target = document.querySelector(window.location.hash);
    //                   anime({
    //                     targets: globalVar.scrollElement,
    //                     scrollTop: (globalVar.scrollElement.scrollTop+target.getBoundingClientRect().top),
    //                     duration: 600,
    //                     delay: 200,
    //                     easing: 'easeOutQuad',
    //                     complete: function () {
    //                       document.querySelector('body').classList.remove('scroll');
    //                     }
    //                   });
    //                 }
    //               }
    //             });
    //           }
    //         });
    //       }
    //     }
    //   });
    // }

    let animePage = () => {
      let up = false;
      if(scrollPos > window.pageYOffset) {
        up = true;
      }
      scrollPos = window.pageYOffset;

      if((scrollPos > 75) && (!cache.header.classList.contains('small'))) {
        cache.header.classList.add('small');
      }
      if((scrollPos < 75) && (cache.header.classList.contains('small'))) {
        cache.header.classList.remove('small');
      }

      if((scrollPos+100 > window.innerHeight) && (cache.header.classList.contains('no-border'))) {
        cache.header.classList.remove('no-border');
      }
      if((scrollPos+100 < window.innerHeight) && (!cache.header.classList.contains('no-border'))) {
        cache.header.classList.add('no-border');
      }

      for (let i = 0; i < cache.bg_dark.length; i++) {
        let top = utils.getOffsetTop(cache.bg_dark[i]);
        if((scrollPos+(window.innerHeight/1.9) > top) && (scrollPos+(window.innerHeight/1.9) < (top+cache.bg_dark[i].offsetHeight)) && (!cache.body.classList.contains('theme_dark'))){
          cache.body.classList.add('theme_dark');
        }
        else if((scrollPos+(window.innerHeight/1.9) < top-10) && (cache.body.classList.contains('theme_dark'))){
          cache.body.classList.remove('theme_dark');
        }
        else if((scrollPos+(window.innerHeight/1.9) > (top+cache.bg_dark[i].offsetHeight+100)) && (cache.body.classList.contains('theme_dark'))){
          cache.body.classList.remove('theme_dark');
        }
      }
      for (let i = 0; i < cache.bg_green.length; i++) {
        let top = utils.getOffsetTop(cache.bg_green[i]);
        if((scrollPos+(window.innerHeight/2) > top) && (scrollPos+(window.innerHeight/2) < (top+cache.bg_green[i].offsetHeight)) && (!cache.body.classList.contains('theme_green'))){
          cache.body.classList.add('theme_green');
        }
        else if((scrollPos+(window.innerHeight/2) < top-10) && (cache.body.classList.contains('theme_green'))){
          cache.body.classList.remove('theme_green');
        }
        else if((scrollPos+(window.innerHeight/2) > (top+cache.bg_green[i].offsetHeight+10)) && (cache.body.classList.contains('theme_green'))){
          cache.body.classList.remove('theme_green');
        }
      }

      for (let i = 0; i < cache.animeTxt.length; i++) {
        let top = utils.getOffsetTop(cache.animeTxt[i]);
        if((scrollPos+(window.innerHeight/1.9) > top) && (!cache.animeTxt[i].classList.contains('animated'))){
          cache.animeTxt[i].classList.add('animated');
          if( cache.animeTxt[i].querySelector('.anime-txt_round')) {
            let round = cache.animeTxt[i].querySelectorAll('.anime-txt_round');
            for (let j = 0; j < round.length; j++) {
              anime({
                targets: round[j],
                scale:['0.8', 1],
                opacity: [0,1],
                duration: 400,
                delay: 100 * j + 200,
                easing: 'easeOutSine',
              });
            }
          }
          if( cache.animeTxt[i].querySelector('.anime-txt_round-center')) {
            let roundCenter = cache.animeTxt[i].querySelectorAll('.anime-txt_round-center');
            for (let k = 0; k < roundCenter.length; k++) {
              anime({
                targets: roundCenter[k],
                scale:['0.8', 1],
                translateX: ['-50%','-50%'],
                translateY: ['-50%','-50%'],
                opacity: [0,1],
                duration: 400,
                delay: 100 * k + 200,
                easing: 'easeOutSine',
              });
            }
          }

          if( cache.animeTxt[i].querySelector('.anime-txt')) {
            let txt = cache.animeTxt[i].querySelectorAll('.anime-txt');
            for (let l = 0; l < txt.length; l++) {
              anime({
                targets: txt[l],
                translateY:['60%', '0%'],
                opacity: [0,1],
                duration: 1000,
                delay: 100 * l,
                easing: 'easeOutSine',
              });
            }
          }

        }
      }


      for (let i = 0; i < cache.animeFact.length; i++) {
        let top = utils.getOffsetTop(cache.animeFact[i]);
        if((scrollPos+(window.innerHeight/1.6) > top) && (scrollPos+(window.innerHeight/1.6) < (top+cache.animeFact[i].offsetHeight)) ){
          let txt = cache.animeFact[i].querySelectorAll('.anime-fact_txt');
          if(!cache.animeFact[i].classList.contains('animetxt')) {
            cache.animeFact[i].classList.add('animetxt');
            for (let j = 0; j < txt.length; j++) {
              anime({
                targets: txt[j],
                opacity: [0.5,1],
                duration: 1000,
                delay: 200 * j,
                easing: 'easeOutSine',
              });
            }
          }

          if((cache.animeFact[i].dataset.index !== "0") && globalVar.desktop.matches) {
            let img = cache.animeFactImg[cache.animeFact[i].dataset.index];
            let animeImg = anime({
              targets: img,
              translateY: ["110%","0%"],
              duration: 1000,
              easing: 'easeOutSine',
              autoplay: false
            });
            let percent = ((scrollPos+(window.innerHeight/2)) - top) / window.innerHeight;
            animeImg.seek((percent*2.2) * animeImg.duration);
          }
        }
        else if((scrollPos+(window.innerHeight/1.6) > (top+cache.animeFact[i].offsetHeight)) && (cache.animeFact[i].classList.contains('animetxt')) && globalVar.desktop.matches){
            cache.animeFact[i].classList.remove('animetxt');
            let txt = cache.animeFact[i].querySelectorAll('.anime-fact_txt');
            for (let j = 0; j < txt.length; j++) {
              anime({
                targets: txt[j],
                opacity: [1, 0.5],
                duration: 1000,
                delay: 200 * j,
                easing: 'easeOutSine',
              });
            }
        }
        else if((scrollPos+(window.innerHeight/1.6) < top) && (cache.animeFact[i].classList.contains('animetxt')) && globalVar.desktop.matches){
            cache.animeFact[i].classList.remove('animetxt');
            let txt = cache.animeFact[i].querySelectorAll('.anime-fact_txt');
            for (let j = 0; j < txt.length; j++) {
              anime({
                targets: txt[j],
                opacity: [1, 0.5],
                duration: 1000,
                delay: 200 * j,
                easing: 'easeOutSine',
              });
            }
        }

      }


      for (let i = 0; i < cache.animeProd.length; i++) {
        let top = utils.getOffsetTop(cache.animeProd[i]);
        if((scrollPos+(window.innerHeight) > top) && (scrollPos+(window.innerHeight) < (top+cache.animeProd[i].offsetHeight)) ){
          let round = cache.animeProd[i].querySelector('.anime_img clipPath');
          let animeRoud = anime({
            targets: round,
            scale: [1,2],
            duration: 100,
            easing: 'linear',
            autoplay: false
          });
          let percent = ((scrollPos+(window.innerHeight)) - top) / window.innerHeight;
          animeRoud.seek((percent*2) * animeRoud.duration);
        }
      }


      for (let i = 0; i < cache.animeComment.length; i++) {
        let top = utils.getOffsetTop(cache.animeComment[i]);
        if((scrollPos+(window.innerHeight/1.9) > top) && (scrollPos+(window.innerHeight/1.9) < (top+cache.animeComment[i].offsetHeight)) ){
          for (let ii = 0; ii < cache.animeCommentNum.length; ii++) {
            if(cache.animeCommentNum[ii].classList.contains('active')) {
              cache.animeCommentNum[ii].classList.remove('active');
            }
          }
          for (let ii = 0; ii < cache.animeCommentNumFemmes.length; ii++) {
            if(cache.animeCommentNumFemmes[ii].classList.contains('active')) {
              cache.animeCommentNumFemmes[ii].classList.remove('active');
            }
          }
          let num = cache.animeCommentNum[cache.animeComment[i].dataset.index];
          num.classList.add('active');
          let numFemmes = cache.animeCommentNumFemmes[cache.animeComment[i].dataset.index];
          numFemmes.classList.add('active');
          let txt = cache.animeComment[i].querySelectorAll('.anime-comment_txt');
          if(!cache.animeComment[i].classList.contains('animetxt')) {
            cache.animeComment[i].classList.add('animetxt');
            for (let j = 0; j < txt.length; j++) {
              anime({
                targets: txt[j],
                translateY:['60%', '0%'],
                opacity: [0,1],
                duration: 1000,
                delay: 100 * j,
                easing: 'easeOutSine',
              });
            }
          }
        }
      }
    }

    let numberClick = function(e) {
      e.preventDefault();
      document.querySelector('body').classList.add('scroll');
      let target = document.querySelector(this.getAttribute("href"));
      anime({
        targets: globalVar.scrollElement,
        scrollTop: (globalVar.scrollElement.scrollTop+target.getBoundingClientRect().top),
        duration: 800,
        easing: 'easeOutQuad',
        complete: function () {
          document.querySelector('body').classList.remove('scroll');
        }
      });
    }

    let numberClickMobile = function() {
      let howTarget = cache.animeComment[this.dataset.index];
      let howActive = document.querySelector('.anime-comment_container.active');

      for (let i = 0; i < cache.animeCommentNum.length; i++) {
        if(cache.animeCommentNumMobile[i].classList.contains('active')) {
          cache.animeCommentNumMobile[i].classList.remove('active');
        }
      }

      for (let i = 0; i < cache.animeCommentNumFemmes.length; i++) {
        if(cache.animeCommentNumMobileFemmes[i].classList.contains('active')) {
          cache.animeCommentNumMobileFemmes[i].classList.remove('active');
        }
      }

      this.classList.add('active');
      howActive.classList.remove('active');
      howTarget.classList.add('active');

      anime({
        targets: howActive,
        opacity: 0,
        duration: 400,
        easing: 'easeOutQuad'
      });
      anime({
        targets: howTarget,
        opacity: 1,
        duration: 400,
        easing: 'easeOutQuad'
      });
    }

    initFunc();
  };
}
export { Home }
