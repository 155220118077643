import { Home } from './pages/home'
import { Bonnespratiques } from './pages/bonnespratiques'
import { Defi } from './pages/defi'
import { Form } from './components/form'
import { PopUp } from './components/popup'
import { Nav } from './components/nav'

class App {
  constructor() {
    if(document.querySelector('body.home')) {
      const home = new Home;
      const bonnespratiques = new Bonnespratiques;
      const defi = new Defi;
      const form = new Form;
      const popUp = new PopUp;
      const nav = new Nav;
    }
  }
}

window.onload = function() {
  let app = new App();
};
