import utils from '../utils/utils'
import anime from '../libs/anime'
import globalVar from '../utils/globalVar'

class Bonnespratiques {
  constructor() {
    let cache = {};
    let scrollLeftPos = 0;
    let scrollLeftWrap;
    let initFunc = () => {
      initCache();
      registerEvents();
    }

    let initCache = () => {
      cache.homeCard = document.querySelectorAll('.bonnespratiques .card');
      cache.bpPopup = document.querySelectorAll('.bp_popup');
      cache.bpPopupClose = document.querySelectorAll('.bp_popup .back');
      cache.bpPopupNxt = document.querySelectorAll('.bp_popup .nxt');
    };

    let registerEvents = () => {
      for (let i = 0; i < cache.homeCard.length; i++) {
        cache.homeCard[i].addEventListener('click', bpOpen );
      }
      for (let i = 0; i < cache.bpPopupClose.length; i++) {
        cache.bpPopupClose[i].addEventListener('click', bpClose );
      }
      for (let i = 0; i < cache.bpPopupNxt.length; i++) {
        cache.bpPopupNxt[i].addEventListener('click', bpNxt );
      }
    };

    let bpOpen = function() {
      let popup = cache.bpPopup[this.dataset.index];
      scrollLeftWrap = popup;
      popup.style.display = "block";
      if(globalVar.desktop.matches) {
        utils.disableScroll();
        scrollLeftPos = 0;
        document.addEventListener('wheel', enableHorScroll);
        document.addEventListener('keydown', enableHorScroll);
      }
      else {
        document.querySelector('html').classList.add('overflow');
        document.querySelector('body').classList.add('overflow');
      }
      anime({
        targets: popup,
        opacity: [0,1],
        translateX: ["100%","0%"],
        duration: 600,
        easing: 'easeOutSine',
      });
    }

    let bpClose = function() {
      let popup = this.closest('.bp_popup');
      anime({
        targets: popup,
        opacity: [1,0],
        translateX: ["0%","100%"],
        duration: 600,
        easing: 'easeOutSine',
        complete: function() {
          document.querySelector('html').classList.remove('overflow');
          document.querySelector('body').classList.remove('overflow');
          popup.scrollLeft = 0;
          popup.style.display = "none";
          let anime = popup.querySelectorAll('.animated');
          for (var i = 0; i < anime.length; i++) {
            anime[i].classList.remove('animated');
            anime[i].style.opacity = 0;
          }
          if(globalVar.desktop.matches) {
            document.removeEventListener('wheel', enableHorScroll);
            document.removeEventListener('keydown', enableHorScroll);
            utils.enableScroll();
          }
          else {
            document.querySelector('html').classList.remove('overflow');
            document.querySelector('body').classList.remove('overflow');
          }
        }
      });
    }
    let bpNxt = function() {
      let popup = cache.bpPopup[this.dataset.index];
      let currentPopup = this.closest('.bp_popup');
      scrollLeftWrap = popup;
      popup.style.display = "block";
      popup.style.opacity = "1";
      scrollLeftPos = 0;
      if(globalVar.desktop.matches) {
        anime({
          targets: currentPopup,
          translateX: ["0%","-100%"],
          duration: 600,
          easing: 'easeOutSine',
          complete: function() {
            currentPopup.scrollLeft = 0;
            currentPopup.style.display = "none";
            let anime = currentPopup.querySelectorAll('.animated');
            for (var i = 0; i < anime.length; i++) {
              anime[i].classList.remove('animated');
              anime[i].style.opacity = 0;
            }
          }
        });
        anime({
          targets: popup,
          translateX: ["100%","0%"],
          duration: 600,
          easing: 'easeOutSine',
        });
      }
      else {
        anime({
          targets: currentPopup,
          translateY: ["0%","-100%"],
          duration: 600,
          easing: 'easeOutSine',
          complete: function() {
            currentPopup.scrollTop = 0;
            currentPopup.style.display = "none";
          }
        });
        anime({
          targets: popup,
          translateY: ["100%","0%"],
          duration: 600,
          easing: 'easeOutSine',
        });
      }
    }


    function enableHorScroll(event) {
      document.querySelector('body').classList.add('is_horz_scrolling');
      let scrollDist = (scrollLeftWrap.querySelector('.bp_inner').offsetWidth);
      let animeTxt = scrollLeftWrap.querySelectorAll('.anime-horz_txt');
      let animeImgUp = scrollLeftWrap.querySelectorAll('.anime-horz_img-up');
      let animeImgDown = scrollLeftWrap.querySelectorAll('.anime-horz_img-down');
      let animeRound = scrollLeftWrap.querySelectorAll('.anime-horz_round');

      if(!event.keyCode) {
        if((scrollLeftPos + event.deltaY) > scrollDist) {
          scrollLeftPos += (scrollDist - scrollLeftPos);
          scrollLeftWrap.scrollLeft = scrollLeftPos;
        }

        else if(((event.deltaY) < 0) && ((scrollLeftPos + event.deltaY) < 0)) {
          scrollLeftPos = 0;
          scrollLeftWrap.scrollLeft = scrollLeftPos;
        }

       else {
         scrollLeftPos += event.deltaY;
         scrollLeftWrap.scrollLeft = scrollLeftPos;
       }
     }
     else {
       var distScroll = 60;
       if(event.keyCode == 40) {
         if((scrollLeftPos + distScroll) < scrollDist) {
           scrollLeftPos += distScroll;
           scrollLeftWrap.scrollLeft = scrollLeftPos;
         }
         else {
           scrollLeftPos += distScroll;
           scrollLeftWrap.scrollLeft = scrollLeftPos;
         }
       }
       else if(event.keyCode == 38) {
         if((scrollLeftPos - distScroll) > 0) {
           scrollLeftPos -= distScroll;
           scrollLeftWrap.scrollLeft = scrollLeftPos;
         }
         else {
           scrollLeftPos = 0;
           scrollLeftWrap.scrollLeft = 0;
         }
       }
     }

     for (var i = 0; i < animeTxt.length; i++) {
       if(scrollLeftPos + window.innerWidth/1.2 > utils.getOffsetLeft(animeTxt[i]) && !animeTxt[i].classList.contains('animated')) {
         animeTxt[i].classList.add('animated');
         anime({
           targets:  animeTxt[i],
           translateX:['20%', '0%'],
           opacity: [0,1],
           duration: 800,
           delay: 100 * i,
           easing: 'easeOutSine',
         });
       }
     }
     for (var i = 0; i < animeImgUp.length; i++) {
       if(scrollLeftPos + window.innerWidth/1.2 > utils.getOffsetLeft(animeImgUp[i]) && !animeImgUp[i].classList.contains('animated')) {
         animeImgUp[i].classList.add('animated');
         anime({
           targets:  animeImgUp[i],
           translateY:['20%', '0%'],
           opacity: [0,1],
           duration: 800,
           delay: 100 * i,
           easing: 'easeOutSine',
         });
       }
     }
     for (var i = 0; i < animeImgDown.length; i++) {
       if(scrollLeftPos + window.innerWidth/1.2 > utils.getOffsetLeft(animeImgDown[i]) && !animeImgDown[i].classList.contains('animated')) {
         animeImgDown[i].classList.add('animated');
         anime({
           targets:  animeImgDown[i],
           translateY:['-20%', '0%'],
           opacity: [0,1],
           duration: 800,
           delay: 100 * i,
           easing: 'easeOutSine',
         });
       }
     }
     for (var i = 0; i < animeRound.length; i++) {
       if(scrollLeftPos + window.innerWidth/1.2 > utils.getOffsetLeft(animeRound[i]) && !animeRound[i].classList.contains('animated')) {
         animeRound[i].classList.add('animated');
         anime({
           targets: animeRound[i],
           scale:[0.8, 1],
           opacity: [0,1],
           duration: 400,
           easing: 'easeOutSine',
         });
       }
     }
    }

    initFunc();
  };
}
export { Bonnespratiques }
