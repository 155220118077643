import utils from '../utils/utils'
import anime from '../libs/anime'
import globalVar from '../utils/globalVar'

class Nav {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
    }

    let initCache = () => {
      cache.nav = document.querySelector('header');
      cache.navLinks = cache.nav.querySelectorAll('.nav a, .btn');
    };

    let registerEvents = () => {
      for (let i = 0; i < cache.navLinks.length; i++) {
        cache.navLinks[i].addEventListener('click', scrollTo);
      }
    };

    let scrollTo = function(e) {
      document.querySelector('body').classList.add('scroll');
      e.preventDefault();
      var target = document.querySelector(this.getAttribute("href"));
      anime({
        targets: globalVar.scrollElement,
        scrollTop: (globalVar.scrollElement.scrollTop+target.getBoundingClientRect().top),
        duration: 600,
        easing: 'easeOutQuad',
        complete: function () {
          document.querySelector('body').classList.remove('scroll');
        }
      });
    }

    initFunc();
  };
}
export { Nav }
