import utils from '../utils/utils'
import anime from '../libs/anime'
import globalVar from '../utils/globalVar'

class Form {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
    }

    let initCache = () => {
      cache.form_wrap = document.querySelector('.form_wrap');
      cache.form_bg = cache.form_wrap.querySelector('.bg')
      cache.form_container = cache.form_wrap.querySelector('.form_container')
      cache.btn_form = document.querySelector('.form_btn');
      cache.close = cache.form_wrap.querySelector('.close');
    };

    let registerEvents = () => {
      cache.btn_form.addEventListener('click', openForm);
    };

    let openForm = () => {
      cache.btn_form.removeEventListener('click', openForm);
      document.querySelector('html').classList.add('overflow');
      document.querySelector('body').classList.add('overflow');
      cache.form_wrap.style.display="block";
      anime({
        targets: cache.form_bg,
        opacity: [0,1],
        duration: 400,
        easing: 'easeOutQuad'
      });
      anime({
        targets: cache.form_container,
        translateX: ['100%','0%'],
        duration: 500,
        delay: 200,
        easing: 'easeOutQuad',
        complete: function() {
          cache.close.addEventListener("click", closeForm);
          cache.form_bg.addEventListener("click", closeForm);
        }
      });
    }

    let closeForm = () => {
      cache.close.removeEventListener("click", closeForm);
      cache.form_bg.removeEventListener("click", closeForm);
      document.querySelector('body').classList.remove('overflow');
      document.querySelector('html').classList.remove('overflow');
      anime({
        targets: cache.form_bg,
        opacity: [1,0],
        duration: 400,
        delay:200,
        easing: 'easeOutQuad'
      });
      anime({
        targets: cache.form_container,
        translateX: ['0%','100%'],
        duration: 500,
        easing: 'easeOutQuad',
        complete: function() {
          cache.btn_form.addEventListener('click', openForm);
          cache.form_wrap.style.display="none";
        }
      });
    }

    initFunc();
  };
}
export { Form }
