import utils from '../utils/utils'
import anime from '../libs/anime'
import globalVar from '../utils/globalVar'

class PopUp {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
    }

    let initCache = () => {
      cache.page = document.querySelector('body');
      cache.btn_popup = cache.page.querySelectorAll('.btn_popup');
      cache.close_popup = cache.page.querySelectorAll('.close_popup');
      cache.close_bg = cache.page.querySelectorAll('.popup_parent .bg');
    };

    let registerEvents = () => {
      for (let i = 0; i < cache.btn_popup.length; i++) {
        cache.btn_popup[i].addEventListener('click',openPopUp);
      }
      for (let i = 0; i < cache.close_popup.length; i++) {
        cache.close_popup[i].addEventListener('click',closePopUp);
      }
      for (let i = 0; i < cache.close_bg.length; i++) {
        cache.close_bg[i].addEventListener('click',closePopUp);
      }
    };

    let openPopUp = function() {
      utils.disableScroll();
      let popUp = cache.page.querySelector('.'+this.dataset.popup);
      popUp.style.display="flex";
      anime({
        targets: popUp,
        opacity: [0,1],
        duration: 400,
        easing: 'easeOutQuad'
      });
      anime({
        targets: popUp.querySelector('.player'),
        scale: ['0.85','1'],
        opacity: [0,1],
        duration: 300,
        delay: 200,
        easing: 'easeOutQuad',
        complete: function() {
          if(popUp.querySelector('iframe')) {
            popUp.querySelector('iframe').contentWindow.postMessage('{"method":"play"}', '*');
          }
          else if(popUp.querySelector('video')) {
            popUp.querySelector('video').play();
          }
        }
      });
    }

    let closePopUp = function() {
      let popUp = this.closest('.popup_parent');
      anime({
        targets: popUp,
        opacity: [1,0],
        delay: 200,
        duration: 400,
        easing: 'easeOutQuad'
      });
      anime({
        targets: popUp.querySelector('.player'),
        scale: ['1','0.85'],
        opacity: [1,0],
        duration: 300,
        easing: 'easeOutQuad',
        complete: function() {
          if(popUp.querySelector('iframe')) {
            popUp.querySelector('iframe').contentWindow.postMessage('{"method":"pause"}', '*');
          }
          else if(popUp.querySelector('video')) {
            popUp.querySelector('video').pause();
          }
          utils.enableScroll();
          popUp.style.display="none";
        }
      });
    }

    initFunc();
  };
}
export { PopUp }
